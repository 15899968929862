import React, { useState, useEffect, useRef } from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Slider from 'react-slick';
import img01C from './images/banner_05.webp'
import Icon01 from './images/ad04.webp'
import Icon02 from './images/ad05.webp'
import Icon03 from './images/ad06.webp'
import Icon04 from './images/ad07.webp'
import Icon05 from './images/ad08.webp'
import Icon06 from './images/ad09.webp'
import Icon07 from './images/Icon07.webp'
import Icon08 from './images/Icon08.webp'
import setupbox2 from './images/setupbox2.webp'
import Ftr01 from './images/adfeature01.webp'
import Ftr02 from './images/adfeature02.webp'
import Ftr03 from './images/adfeature03.webp'
import Ftr04 from './images/Ftr_04.webp'
import Ftr05 from './images/Ftr_05.webp'
import Ftr07 from './images/Ftr07.webp'
import Ftrl01 from './images/4khd.webp'
import Ftrl02 from './images/dublyvision.webp'
import Ftrl03 from './images/dubly-audio.webp'
import Ftrl05 from './images/Ftrl05.webp'
import Ftrl06 from './images/Ftrl06.webp'
import { Ottslider } from './components/Ottslider'
import Channels from './images/channels.webp'
import Playstore from './images/playstore.webp'
import Okgooglecurve from './images/okgooglecurve.webp'
import details from './images/details.webp'
import Voicesearch from './images/voice_search.webp'
import Screenshare from './images/screenshare.webp'
import LIVEtv from './images/livetv.webp'
import Ultra4k from './images/ultra4k.webp'
import Ultra4khd from './images/4khd.webp'

import { Link } from 'react-router-dom';
import Plan_bg from "./images/Plan_Bg.webp"
import img02 from './images/android-tv.webp';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Form } from './components/Form';
import { Ottplans } from './components/Ottplans';
import { Effectarrow } from './components/Effectarrow';
import axios from 'axios';
import { LiveTv } from './LiveTv';

export const Iptv = () => {
    var decodeHTML = function (html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    function createMarkup(content) {
        return { __html: decodeHTML(content) };
    }
    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };

    const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/plans.php?catID=17`)
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        };
    }, []);

    console.log(pagedata);
    if (pagedata.length === 0) {
        console.log('no data');
        // return null;
    }




    const [user_mobile, setusermobile] = useState();
    const [plans, setPlans] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const ref_mobile = useRef(null);

    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_plans.php?cid=17')
            .then(response => setPlans(response.data))
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        axios.get(window.weburl + 'api/get_states.php')
            .then(response => setStates(response.data))
            .catch(error => console.log(error));
    }, []);

    function getCities(state) {
        axios.get(window.weburl + `api/get_cities.php?sid=${state}`)
            .then(response => setCities(response.data))
            .catch(error => console.log(error));
    }

    const handledisable = () => {
        if (isValidPhone(user_mobile) && (user_mobile)) {
            const element = document.getElementById('submit_button_text');
            element.classList.remove('disabled');
            setIsDisabled(false);
        } else {
            const element = document.getElementById('submit_button_text');
            element.classList.add('disabled');
            setIsDisabled(true);
        }
    };

    function isValidPhone(phone) {
        const regex = /^[6-9]\d{9}$/gi;
        return regex.test(phone);
    }

    const handleSubmit = () => {
        if (!isValidPhone(user_mobile)) {
            alert("Please enter Mobile number");
            ref_mobile.current.focus();
            return false;
        }
        else {
            const url = window.weburl + "api/insert_hook.php";
            let fData = new FormData();
            fData.append('user_mobile', user_mobile);
            fData.append('catid', '21');
            fData.append('pid', selectedPlan);
            fData.append('user_state', selectedState);
            fData.append('user_city', selectedCity);
            fData.append('td_name', 'androidtv');
            fData.append('utm_url', window.location.href);
            axios.post(url, fData)
                .then(response => alert(response.data))
                .catch(error => alert(error));
            window.location.reload();
        }
    }

    return (
        <>
            <Header />
            <section id='banner' className='p-0 grey-color internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner'>
                    <div className='leftarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='home-slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='sliderrow'>
                                                <div className='slideimg'>
                                                    <img src={img01C} alt='Banner Netplus' />
                                                </div>
                                                <div className='slidecont'>
                                                    <div className='circle01'>
                                                        <div className='mngcircle'>
                                                            <div className='circlimg'>
                                                                <h2>Discover a new dimension of TV with FW+</h2>
                                                            </div>
                                                            <div className='prize'>
                                                                <div class="listpoint">
                                                                    {/* <ul>
                                                                        <li>450+ Live TV Channels</li>
                                                                        <li>50 Mbps UL Data</li>
                                                                        <li>Premium OTT Apps</li>

                                                                    </ul> */}
                                                                     <img src={details} alt="" />
                                                                </div>
                                                                <div className='pack shape pricestn'>
                                                                    <div className='pprice'>
                                                                        {/* <ul>
                                                                            <li><strong>3695 /-</strong></li>
                                                                        </ul> */}
                                                                        <h5>Plans starts<br/> @ just <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className='finr'></i>299/-</strong></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='setupbox'>
                                                                <img src={setupbox2} alt='' />
                                                            </div>
                                                            <div className='booknow justify-content-start'>
                                                                <div className="wrap"><Link className="btn-11" to='book-now/dDZvSHptbzl5eEg1Zm0xb1lsYmZHZz09'><span>Get New Connection</span></Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='filter' className='grey-color'>
                <Form cat="at" />
            </section>
            <section id='androidpoints'>
                <div className='androidpoints'>
                    <div className='leftarrow'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow '>
                        <Effectarrow />
                    </div>
                    
                </div>
            </section>
            <section id='androidtv' className='pt-0'>
                <div className='androidtv'>
                    <div className='leftarrow'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow '>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                {/* <div className='androidtvrow'>
                                    <div className='androidtvrow3' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <img src={setupbox2} alt='SetUp Box' />
                                    </div>
                                    <div className='androidbox7'>
                                        <div className='andoidcont' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>Entertainment that Strikes a Chord!</h4>
                                            <p>Your Ultimate TV experience awaits with FW+ IPTV - Welcome to smarter way to watch your favourite content. Convert your normal TV to smart TV and enjoy host of unlimited entertainment.</p>
                                            <div className='booknow justify-content-start'>
                                                <div className="wrap m-0"><Link className="btn-11" to='/book-now/Ynl3WVU4N1h3ZWtDTVpNZ29YbmQyQT09'><span>Get Now</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                               
                                <div className='planstab'>
                                    <Tabs>
                                        <TabList>
                                            <Tab>IPTV PLANS</Tab>
                                            {/* <Tab>OTT Plans</Tab> */}
                                        </TabList>

                                        {pagedata.data ?
                                            <TabPanel>
                                                <div className='planstabpanel'>
                                                    <div className='androidtv' id='plans'>
                                                        <div className='bundlestn'>
                                                            {pagedata.data.map(getData => {
                                                                const newValues = getData.features.split('|_');
                                                                return <div className='bundlebox'>
                                                                    <h4 dangerouslySetInnerHTML={createMarkup(getData.pname)}></h4>
                                                                    <div className='image_plans_div'>
                                                                        <div className="image_plan">
                                                                            <img src={Plan_bg} alt="Plan Image" />
                                                                        </div>
                                                                        <p dangerouslySetInnerHTML={createMarkup(getData.ptitle)}></p>
                                                                    </div>
                                                                    {getData.features ?
                                                                        <ul>
                                                                            {newValues.map(featuresValue => {
                                                                                return <>
                                                                                    {featuresValue ?
                                                                                        <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                                                                        : null}
                                                                                </>
                                                                            })}
                                                                        </ul>
                                                                        : null}

                                                 {getData.icon_values ?
                                                <div className='entertainment'>
                                              <Ottslider geticonValues={getData.icon_values}/>
                                              </div>
                                                     : null}


                                                                    <div className='price'>
                                                                        <h6>{getData.price}</h6>
                                                                    </div>

                                                                    <div className='btn-11 fill-color'>
                                                                        <Link to={'/book-now/' + getData.plan_id}><span>Get Now</span></Link>
                                                                    </div>
                                                                </div>
                                                            })}


                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            : null}
                                        {/* <TabPanel>
                                            <div className='planstabpanel'>
                                                <Ottplans />
                                            </div>
                                        </TabPanel> */}
                                    </Tabs>
                                </div>
  {/* <div className='androidtvrow'>
                                    <div className='androidtvrow3' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <img src={setupbox2} alt='SetUp Box' />
                                    </div>
                                    <div className='androidbox7'>
                                        <div className='andoidcont' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>Entertainment that Strikes a Chord!</h4>
                                            <p>Your Ultimate TV experience awaits with FW+ IPTV - Welcome to smarter way to watch your favourite content. Convert your normal TV to smart TV and enjoy host of unlimited entertainment.</p>
                                            <div className='booknow justify-content-start'>
                                                <div className="wrap m-0"><Link className="btn-11" to='/book-now/Ynl3WVU4N1h3ZWtDTVpNZ29YbmQyQT09'><span>Get Now</span></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  */}
                                
                                <div className='androidfeature' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><span><img src={Ftr07} alt='' /><img src={Ftrl01} alt='' /></span></li>
                                        <li><span><img src={Ftr05} alt='' /><img src={Ftrl06} alt='' /></span></li>
                                        <li><span><img src={Ftr04} alt='' /><img src={Ftrl05} alt='' /></span></li>
                                    </ul>
                                </div>
                                {/* <div className='planbanner'>
                                    <div className='plancont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <h4>Plug. Play. Out of the way!</h4>
                                        <p>Connect & access unlimited content.</p>
                                    </div>
                                </div> */}
                                <div className='clearfix'></div>
                          

                            </div>
                        </div>
                    </div>
<br/><br/>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading'>
                                    <h4>FW+ TV Bundle</h4>
                                </div>
                                <div className='gradienticons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><span><img src={Icon01} alt='' /></span><p>700+ Channels</p></li>
                                        <li><span><img src={Icon02} alt='' /></span><p>4K Ultra HD</p></li>
                                        <li><span><img src={Icon07} alt='' /></span><p>Bluetooth Remote</p></li>
                                        <li><span><img src={Icon08} alt='' /></span><p>Tango TV App</p></li>
                                        <li><span><img src={Icon05} alt='' /></span><p>Dual-Band</p></li>
                                        <li><span><img src={Icon06} alt='' /></span><p>Micro SD XC</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='coreadvantages' className='pt-0'>
                <div className='coreadvantages androidbox'>
                    <div className='leftarrow'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow '>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='squarecont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <ul>
                                        <li><p>Apps you'll love on your TV</p><img src={Channels} alt='play store' /><p>Revolutionize your TV time with apps <br />designed to redefine your TV experience.</p></li>
                                        <li><p>Control Your HD World with Voice Assistant</p><img src={Voicesearch} alt='play store' /><p>Combines the power of voice control with<br />the high-quality HD streaming.</p></li>
                                        <li><p>Stream your world in HD Excellence</p><img src={LIVEtv} alt='play store' /><p>Highlights the reliability of HD quality in<br /> providing a superior viewing experience.</p></li>
                                        <li><p>Stunning ultra 4k picture quality</p><img src={Ultra4k} alt='play store' /><p>Experience Every Detail in Ultra 4K Brilliance<br /> for Unmatched entertainment.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
            <Footer />
        </>
    )
}
